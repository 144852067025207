import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import HeaderContactBanner from "./HeaderContactBanner"
import { OutboundLink } from "gatsby-plugin-gtag"

export default function Header() {
  // const [isBlogOpen, setIsBlogOpen] = useState(false)

  // const handleBlogClick = () => {
  //   setIsBlogOpen(!isBlogOpen)
  // }

  const [isMobileOpen, setIsMobileOpen] = useState(false)

  const handleMobileClick = () => {
    setIsMobileOpen(!isMobileOpen)
  }

  // const data = useStaticQuery(graphql`
  //   query HeaderImageQuery {
  //     file(relativePath: { eq: "KristiAdams_1000x.jpg" }) {
  //       childImageSharp {
  //         # Specify the image processing specifications right in the query.
  //         fluid {
  //           ...GatsbyImageSharpFluid_tracedSVG
  //         }
  //       }
  //     }
  //     allMdx(sort: { order: DESC, fields: [frontmatter___date] }, limit: 3) {
  //       edges {
  //         node {
  //           slug
  //           frontmatter {
  //             title
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  const data = useStaticQuery(graphql`
    query HeaderImageQuery {
      file(relativePath: { eq: "KristiAdams_1000x.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  // const { edges } = data.allMdx
  return (
    <header className="relative bg-london-navyblue  border-b-2 border-london-darkred">
      <nav>
        <div className="max-w-5xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center  py-6 md:justify-start md:space-x-10">
            <div className="lg:w-0 lg:flex-1">
              <Link to="/" className="flex" aria-label="Kristi Adams Realtor">
                <div className=" flex flex-col items-center pt-4 pb-2 px-4 text-gray-50 leading-none">
                  <span className="text-4xl font-serif  font-normal border-red-800 border-b-4 ">
                    Kristi Adams{" "}
                  </span>
                  <div className="mt-1 flex tracking-wider items-baseline text-gray-200 text-xs uppercase">
                    <span className="font-serif tracking-wider">Realtor</span>{" "}
                    <span>-</span>{" "}
                    <span className="font-serif pr-1 border-red-800 border-r-4">
                      Lic #02108366
                    </span>
                  </div>
                </div>
              </Link>
            </div>

            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                aria-label="Menu"
                onClick={handleMobileClick}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>

            <div className="hidden md:flex space-x-10">
              <Link
                to="/"
                activeClassName="text-gray-300"
                aria-label="Home"
                className="text-base leading-6 font-medium text-gray-400 hover:text-gray-200 focus:outline-none focus:text-gray-200  transition ease-in-out duration-150"
              >
                Home
              </Link>
              <Link
                to="/contact/"
                activeClassName="text-gray-300"
                className="text-base leading-6 font-medium text-gray-400 hover:text-gray-200 focus:outline-none focus:text-gray-200 transition ease-in-out duration-150"
              >
                Contact
              </Link>
              <Link
                to="/about/"
                activeClassName="text-gray-300"
                aria-label="About Kristi"
                className="text-base leading-6 font-medium text-gray-400 hover:text-gray-200 focus:outline-none focus:text-gray-200 transition ease-in-out duration-150"
              >
                About
              </Link>

              {/* Blog menu */}
              {/* <div className="relative">
              <button
                type="button"
                onClick={handleBlogClick}
                className="text-gray-400 inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-200 focus:outline-none focus:text-gray-200 transition ease-in-out duration-150"
              >
                <span>Blog</span>

                <svg
                  className="text-gray-400 h-5 w-5 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button> */}

              {/* <!--
            'Blog' flyout menu, show/hide based on flyout menu state.

            Entering: "transition ease-out duration-200"
              From: "opacity-0 translate-y-1"
              To: "opacity-100 translate-y-0"
            Leaving: "transition ease-in duration-150"
              From: "opacity-100 translate-y-0"
              To: "opacity-0 translate-y-1"
          --> */}
              {/* {isBlogOpen && (
                <div className="absolute left-1/2 transform z-30 -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                  <div className="rounded-lg shadow-lg">
                    <div className="rounded-lg shadow-xs overflow-hidden">
                      <div className="px-5 py-5 bg-gray-50 space-y-5 sm:px-8 sm:py-8">
                        <div className="space-y-4">
                          <h3 className="text-sm leading-5 tracking-wide font-medium text-gray-500 uppercase">
                            Recent Posts
                          </h3>
                          <ul className="space-y-4">
                            {edges.map(edge => {
                              const { frontmatter, slug } = edge.node

                              return (
                                <li
                                  key={slug}
                                  className="text-base leading-6 truncate"
                                >
                                  <Link
                                    to={`/${slug}`}
                                    className="font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150"
                                  >
                                    {frontmatter.title}
                                  </Link>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                        <div className="text-sm leading-5">
                          <Link
                            to="/blog"
                            onClick={handleBlogClick}
                            className="font-medium text-london-darkred hover:text-gray-500 transition ease-in-out duration-150"
                          >
                            View all posts &rarr;
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div> */}
            </div>

            <div className="hidden lg:flex items-center justify-end space-x-8 lg:flex-1 lg:w-0">
              <OutboundLink
                href="http://londonproperties.com"
                className="whitespace-no-wrap text-base leading-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900"
              >
                <div className="flex flex-col items-center pt-4 pb-2 px-4 text-gray-50 leading-none">
                  <span className="text-4xl font-serif font-normal uppercase border-red-800 border-b-4">
                    London
                  </span>
                  <span className=" mt-1  uppercase font-hairline font-serif text-gray-200 text-xs tracking-widest border-red-800 border-r-4">
                    Properties
                  </span>
                </div>
              </OutboundLink>
            </div>
          </div>
        </div>

        {/* <!--
    Mobile menu, show/hide based on mobile menu state.

    Entering: "duration-200 ease-out"
      From: "opacity-0 scale-95"
      To: "opacity-100 scale-100"
    Leaving: "duration-100 ease-in"
      From: "opacity-100 scale-100"
      To: "opacity-0 scale-95"
  --> */}
        {isMobileOpen && (
          <div className="absolute top-0 z-30 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-lg">
              <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5 space-y-6">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className=" flex flex-col items-center pt-4 pb-2 px-4 text-london-navyblue leading-none">
                        <span className="text-4xl font-serif  font-normal border-red-800 border-b-4 ">
                          Kristi Adams
                        </span>
                        <div className="mt-1 flex tracking-wider items-baseline text-london-navyblue text-xs uppercase">
                          <span className="font-serif tracking-wider">
                            Realtor
                          </span>
                          <span>-</span>
                          <span className="font-serif pr-1 border-red-800 border-r-4">
                            Lic #02108366
                          </span>
                        </div>
                      </div>
                      <span className="inline-block relative">
                        <Img
                          className="h-16 w-16 rounded-full"
                          fluid={data.file.childImageSharp.fluid}
                          alt="Kristi Adams local realtor"
                        />
                      </span>
                    </div>
                    <div className="-mr-2">
                      <button
                        type="button"
                        aria-label="Close Menu"
                        onClick={handleMobileClick}
                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                      >
                        <svg
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="grid gap-y-8">
                      <Link
                        to="/"
                        onClick={handleMobileClick}
                        className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="flex-shrink-0 h-6 w-6 text-london-darkred"
                        >
                          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                        </svg>
                        <div className="text-base leading-6 font-medium text-gray-900">
                          Home
                        </div>
                      </Link>
                      <Link
                        to="/contact"
                        onClick={handleMobileClick}
                        className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="flex-shrink-0 h-6 w-6 text-london-darkred"
                        >
                          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                        </svg>
                        <div className="text-base leading-6 font-medium text-gray-900">
                          Contact
                        </div>
                      </Link>
                      <Link
                        to="/about"
                        onClick={handleMobileClick}
                        className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="flex-shrink-0 h-6 w-6 text-london-darkred"
                        >
                          <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                        </svg>
                        <div className="text-base leading-6 font-medium text-gray-900">
                          About
                        </div>
                      </Link>
                      {/* <Link
                      to="/blog"
                      onClick={handleMobileClick}
                      className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="flex-shrink-0 h-6 w-6 text-london-darkred"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <div className="text-base leading-6 font-medium text-gray-900">
                        Blog
                      </div>
                    </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <HeaderContactBanner />
      </nav>
    </header>
  )
}
