import React from "react"
import { Helmet } from "react-helmet"

export default function SchemaOrgLocalBusiness({
  siteUrl,
  description,
  image,
  socialLinks,
  email,
  telephone,
}) {
  const localBusinessSchema = {
    "@context": "http://schema.org",
    "@type": "RealEstateAgent",
    "@id": siteUrl,
    name: "Kristi Adams Realtor",
    alternateName: "Kristi Adams Realtor - London Properties",
    description: description,
    url: siteUrl,
    sameAs: [
      "https://www.londonproperties.com/agent/17944/kristi-adams/page/6777/welcome/",
      socialLinks.facebook,
      socialLinks.instagram,
      "https://www.realtor.com/realestateagents/kristi-adams_kingsburg_ca_4071375_771979438",
      "https://www.zillow.com/profile/KristiAdamsRealtor/",
      "https://www.linkedin.com/in/kristiadamsrealtor/",
      "https://g.page/KristiAdamsRealtor?gm",
      "https://www.yelp.com/biz/kristi-adams-london-properties-kingsburg",
    ],
    image: {
      "@type": "ImageObject",
      url: image,
    },
    logo: "https://www.kristiadamsrealtor.com/logo.jpg",
    telephone: telephone,
    email: email,
    foundingDate: "03/2020",
    address: {
      "@type": "PostalAddress",
      streetAddress: "1520 Marion St",
      addressLocality: "Kingsburg",
      addressRegion: "CA",
      postalCode: "93631",
      addressCountry: "US",
    },
    review: {
      "@type": "Review",
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
      },
      author: {
        "@type": "Person",
        name: "Joy Thornhill",
      },
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "5",
      ratingCount: "1",
    },
    openingHoursSpecification: [
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        opens: "8:00",
        closes: "20:00",
      },
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: "Saturday",
        opens: "9:00",
        closes: "17:00",
      },
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: "Sunday",
        opens: "9:00",
        closes: "17:00",
      },
    ],
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(localBusinessSchema)}
      </script>
    </Helmet>
  )
}
