import { Link } from "gatsby"
import React from "react"

export default function HeaderContactBanner() {
  return (
    <div className="relative bg-london-red">
      <div className="max-w-screen-xl mx-auto py-2 px-2 sm:px-4 lg:px-6">
        <div className="pr-16 text-center sm:px-16">
          <div className="font-medium text-white">
            {/* <span className="md:hidden">We announced a new product!</span> */}
            <p className="inline">
              <Link
                to="/contact"
                aria-label="Call Kristi at 559 385 0443 or email at kadams@londonproperties.com, link to contact page"
              >
                (559) 385-0443{" "}
                <span className="hidden sm:inline-block">&#8226;</span>{" "}
                <br className="inline-block sm:hidden" />
                kadams@londonproperties.com
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
