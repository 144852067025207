import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import SeoFacebook from "./SeoFacebook"
import SeoTwitter from "./SeoTwitter"
import SchemaOrgLocalBusiness from "./SchemaOrgLocalBusiness"

function SEO({
  title,
  description,
  pathname,
  article,
  image: metaImage,
  banner,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            siteUrl
            defaultTitle: title
            defaultDescription: description
            defaultBanner: banner
            siteLanguage
            author
            email
            telephone
            socialLinks {
              instagram
              facebook
            }
            facebook
            twitter
            ogLanguage
            keywords
          }
        }
      }
    `
  )

  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      siteLanguage,
      author,
      defaultBanner,
      ogLanguage,
      socialLinks,
      // facebook,
      twitter,
      email,
      telephone,
    },
  } = site

  // const metaDescription = description || site.siteMetadata.description
  const image =
    metaImage && metaImage.src
      ? `${site.siteMetadata.siteUrl}${metaImage.src}`
      : null

  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || `${siteUrl}${banner || defaultBanner}`,
    url: `${siteUrl}${pathname || ""}`,
    canonical: canonical,
  }

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: siteUrl,
    // headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: defaultDescription,
    name: defaultTitle,
    author: {
      "@type": "Person",
      name: author,
    },
    copyrightHolder: {
      "@type": "Person",
      name: author,
    },
    copyrightYear: "2020",
    creator: {
      "@type": "Person",
      name: author,
    },
    publisher: {
      "@type": "Person",
      name: author,
    },
    datePublished: "2020-9-22T10:30:00+01:00",
    dateModified: buildTime,
    image: {
      "@type": "ImageObject",
      url: `${siteUrl}${defaultBanner}`,
    },
  }

  // Initial breadcrumb list

  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": siteUrl,
        name: "Homepage",
      },
      position: 1,
    },
  ]

  let schemaArticle = null

  if (article) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name: author,
      },
      copyrightHolder: {
        "@type": "Person",
        name: author,
      },
      copyrightYear: "2020",
      creator: {
        "@type": "Person",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        name: author,
        logo: {
          "@type": "ImageObject",
          url: `${siteUrl}${defaultBanner}`,
        },
      },
      // datePublished: node.first_publication_date,
      // dateModified: node.last_publication_date,
      description: seo.description,
      headline: seo.title,
      inLanguage: siteLanguage,
      url: seo.url,
      name: seo.title,
      image: {
        "@type": "ImageObject",
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    }
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": seo.url,
        name: seo.title,
      },
      position: 2,
    })
  }

  // const breadcrumb = {
  //   "@context": "http://schema.org",
  //   "@type": "BreadcrumbList",
  //   description: "Breadcrumbs list",
  //   name: "Breadcrumbs",
  //   itemListElement,
  // }

  return (
    <>
      <Helmet
        title={seo.title}
        titleTemplate={
          title
            ? `%s \u2022 ${site.siteMetadata.defaultTitle}`
            : `${site.siteMetadata.defaultTitle}`
        }
        link={
          seo.canonical
            ? [
                {
                  rel: "canonical",
                  href: seo.canonical,
                },
              ]
            : []
        }
      >
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {/* <meta name="keywords" content={site.siteMetadata.keywords.join(",")} /> */}

        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {!article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )}

        {/* Breadcrumbs only for blog */}
        {/* Add Breadcrumbs when adding blog */}
        {/* <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script> */}
      </Helmet>
      <SchemaOrgLocalBusiness
        siteUrl={seo.url}
        description={seo.description}
        image={seo.image}
        socialLinks={socialLinks}
        email={email}
        telephone={telephone}
      />
      <SeoFacebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={article ? "article" : "website"}
        url={seo.url}
        locale={ogLanguage}
        name={site.siteMetadata.defaultTitle}
      />
      <SeoTwitter
        title={seo.title}
        image={seo.image}
        desc={seo.description}
        username={twitter}
      />
    </>
  )
}

SEO.defaultProps = {
  meta: [],
  description: ``,
  article: false,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  banner: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  article: PropTypes.bool,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
}

export default SEO
