import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function Award() {
  const data = useStaticQuery(graphql`
    query AwardImageQuery {
      file(relativePath: { eq: "best_of_fres_2016.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    // Maybe use aside?
    <aside
      aria-label="London Properties Fresno Bee People's Choice Award Winner - #1 Best Real Estate Company"
      className="hidden  lg:block  absolute -mt-20 left-auto right-1 xl:right-5 z-10 opacity-100 "
    >
      <Img
        className="w-36 xl:w-44"
        fluid={data.file.childImageSharp.fluid}
        alt="London Properties Fresno Bee People's Choice Award Winner - #1 Best Real Estate Company"
      />
    </aside>
  )
}
