import React from "react"
// import { Helmet } from "react-helmet"

import Footer from "./Footer"
import ContactFloater from "./ContactFloater"
import Header from "./Header"
import Award from "./award"
// import HeaderContactBanner from "./HeaderContactBanner"
// import "../fonts/Inter/inter.css"

export default function Layout({ children }) {
  return (
    <>
      {/* Below is for the interfont */}
      {/* <Helmet>
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
      </Helmet> */}
      <Header />
      <Award />
      {/* <HeaderContactBanner /> */}
      <main>{children}</main>
      <ContactFloater />
      <Footer />
    </>
  )
}
